<template>
  <b-card
    no-body
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body style="overflow: auto">
        <v-grid
          ref="refGridSopCobertura"
          :source="source" 
          :columns="columns" 
          :columnTypes="pluginRevo"
          theme="material" 
          resize="true"
          filter="true"
          autoSizeColumn = true
          class="grid-container-sop-cobertura"
        ></v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody,
  BRow,
} from 'bootstrap-vue'
import { ref, getCurrentInstance } from '@vue/composition-api'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,

    CustomLoading,
    VGrid,
  },
  setup(props) {
    const source = ref([])
    const isLoading = ref(true)
    const refGridSopCobertura = ref(null)
    const columns = ref([])
    const pluginRevo = { 
      'numeric': new NumberColumnType('0,0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    }

    const vm = getCurrentInstance().proxy

    columns.value = [
      {
        "name": "Codigo Item",
        "prop": "codigo_item",
        "size": 150,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "Brand Category",
        "prop": "BrandCategory",
        "size": 180,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "MSL",
        "prop": "MSL",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
      {
        "name": "SPGR",
        "prop": "SPGR",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "Desc SPGR",
        "prop": "Descripcion",
        "size": 250,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "FC",
        "prop": "FC",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
      {
        "name": "Stock",
        "prop": "STOCK",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
      {
        "name": "Cob. Inicial",
        "prop": "COBERTURAINICIAL",
        "size": 135,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
      {
        "name": "Total Ingreso",
        "prop": "TOTALINGRESO",
        "size": 150,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
      {
        "name": "Cob. Final",
        "prop": "COBFINAL",
        "size": 130,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
      {
        "name": "Impacto",
        "prop": "IMPACTO",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
      {
        "name": "NSP",
        "prop": "netsales",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
      {
        "name": "Net Sales Impacto",
        "prop": "NETSALESIMPACTO",
        "size": 190,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "columnType": 'numeric',
      },
    ]

    const fetchData = async () => {
      try {
        isLoading.value = true
        const result = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/cobertura_graph`)

        source.value = result.data.result
        // console.log(result)
        
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }

    fetchData()

    return {
      source,
      columns,
      isLoading,
      refGridSopCobertura,
      pluginRevo,
    }
  },
}
</script>

<style lang="scss">
.grid-container-sop-cobertura {
  // width: 1790px;
  width: 100%;
  height: 85vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
